<template>
  <form v-if="product && shopifyProduct && bundleProducts" id="bundles" class="product-single__bundles form" @submit.prevent>
    <prismic-rich-text
      v-if="product.description"
      :field="product.description"
      class="description wysiwyg"
    />
    <pre v-if="showBundleScript">{{ bundleScript }}</pre>
    <ol>
      <li v-for="(product, i) in bundleProducts" :key="i">
        <BundleProduct
          :product="getProductWithVisibleSKUs(product)"
          :index="i"
          @updateProductSelections="updateProductSelections"
        />
      </li>
    </ol>
    <AddToBag
      v-model="selections.quantity.selected"
      :product="selectedBundleProduct"
      :defaultPrice="price"
      :defaultSalePrice="salePrice"
      :isSoldOut="isSoldOut"
      :isComingSoon="isComingSoon"
      :hideBackInStock="true"
    />
  </form>
</template>

<script>
  import { getTitle, sanitizeShopifyId, getShopifyProduct } from '@/helpers'

  import AddToBag from '@/components/shop/AddToBag'
  import BundleProduct from '@/components/pages/product-single/bundle/BundleProduct'

  export default {
    name: 'BundleProducts',

    components: {
      AddToBag,
      BundleProduct
    },

    data() {
      return {
        selections: {
          quantity: {
            selected: 1
          },
          products: []
        }
      }
    },

    props: {
      title: {
        type: String,
        default: null
      },
      product: {
        type: Object,
        default: null
      },
      shopifyProduct: {
        type: Object,
        default: null
      },
      price: {
        type: [String, Number],
        default: null
      },
      salePrice: {
        type: [String, Number],
        default: null
      },
      bundleProducts: {
        type: Array,
        default: null
      },
      bundleDiscount: {
        type: Number,
        default: 0
      },
      locale: {
        type: String,
        default: null
      }
    },

    computed: {
      hasProductSelections() {
        return this.selections.products && this.selections.products.length > 0;
      },
      isSoldOut() {
        if(!this.hasProductSelections) return true;

        const soldOutVariant = this.selections.products.find(product => {
          return product?.isSoldOut;
        });

        return soldOutVariant ? true : false;
      },
      isComingSoon() {
        if(!this.hasProductSelections) return true;

        const comingSoonVariant = this.selections.products.find(product => {
          return product?.isComingSoon;
        });

        return comingSoonVariant ? true : false;
      },
      selectedBundleProductVariantId() {
        if(!this.hasProductSelections) return null;

        const idArray = this.selections.products.map(product => {
          return sanitizeShopifyId('ProductVariant', product.variantId);
        });

        return idArray.join('');
      },
      selectedBundleProduct() {
        if(!this.hasProductSelections || !this.shopifyProduct) return null;

        const variantId = btoa(`gid://shopify/ProductVariant/${this.shopifyProduct.variants[0].id}`);

        return {
          isBundle: true,
          title: this.title,
          _meta: this.product._meta,
          id: this.shopifyProduct.id,
          variantId: this.selectedBundleProductVariantId,
          bundleVariantId: variantId,
          image: this.shopifyProduct.image,
          brand: this.product.brand,
          price: this.price,
          salePrice: this.salePrice,
          quantity: this.selections.quantity.selected,
          products: this.selections.products
        }
      },
      showBundleScript() {
        return this.$route && this.$route.query.hasOwnProperty('bundle');
      },
      bundleScript() {
        if(!this.showBundleScript) return null;

        if(this.bundleDiscount) {
          const script = {
            bundle_items: [
              {
                product_title: this.title,
                product_id: this.shopifyProduct.id,
                quantity_needed: 1
              }
            ],
            discount_product_selectors: [],
            quantity_to_discount: this.bundleProducts.length,
            discount_message: this.title
          };

          this.bundleProducts.forEach(prod => {
            const product = prod.product;
            const shopifyProduct = getShopifyProduct(product, this.locale);

            if(shopifyProduct) {
              script['discount_product_selectors'].push(shopifyProduct.id);
            }
          });

          return JSON.stringify(script, undefined, 2).replace(/"([^"]+)":/g, '$1:');
        } else {
          return 'This Bundle doesn\'t need a script!';
        }
      }
    },

    methods: {
      updateProductSelections(payload) {
        const newSelections = this.selections.products.filter((selectedProduct) => {
          return selectedProduct.bundleIndex !== payload.bundleIndex;
        });

        this.selections.products = newSelections.concat([payload]);
      },
      getVisibleSKUsByLocale(skus) {
        return skus && skus.split(',').map(item => {
          return item.replace(/\s/g, '');
        });
      },
      getProductWithVisibleSKUs(product) {
        product.product.only_visible_variant_skus_ca = this.getVisibleSKUsByLocale(product.only_visible_variant_skus_ca);
        product.product.only_visible_variant_skus_us = this.getVisibleSKUsByLocale(product.only_visible_variant_skus_us);

        return product.product;
      }
    }
  }
</script>
